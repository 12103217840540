import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb, Collapse, CollapseProps, Flex, message, Row, Space, Typography } from 'antd';
import { SoundOutlined } from '@ant-design/icons';
import SubHeader from '../../../components/layout/SubHeader';
import { DictionaryDto } from '../../../data/interfaces/dictionary';
import { getWordDictionaryById } from '../../../utils/supabase';
import './index.css';

const { Title, Text } = Typography;

const DictionaryDetailPage: React.FC = () => {
  const { id } = useParams();
  const [wordDetail, setWordDetail] = useState<DictionaryDto>();
  const [items, setItems] = useState<CollapseProps['items']>([]);

  const breadcrumbItems = [
    {
      href: '/dictionary',
      title: 'Dictionary'
    },
    {
      title: wordDetail?.simplified
    }
  ]

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id])

  useEffect(() => {
    if (wordDetail) {
      setItems(wordDetail.enriched_llm?.pronunciations.map((p, index) => {
        return {
          key: index, 
          label: (
            <Title level={5} style={{ marginBottom: 0 }}>
              {`${wordDetail.simplified} ${p.pinyin} `}
              {p.audioUrl && <SoundOutlined style={{ color: '#007AFF', fontSize: '1rem', cursor: 'pointer', marginLeft: '.5rem' }} />}
            </Title>
          ), 
          children: meaning(p)}
      }))
    }
  }, [wordDetail])

  const fetchData = async (id: string) => {
    try {
      const response = await getWordDictionaryById(parseInt(id));
      if (response) {
        setWordDetail(response[0]);
      }
    } catch (error) {
      message.error((error as Error).message)
    }
  }

  const meaning = (meanings: any) => (
    <Flex vertical className='w-full' gap={12} style={{ borderBottom: '1px solid #DDE1E6', padding: '.5rem 0' }}>
      {meanings.translations.map((t: any, index: number) => (
        <Flex key={index} vertical>
          <Title level={5} style={{ marginBottom: '.5rem' }}>{index + 1}. {t.english} &nbsp;&nbsp; <span style={{ color: '#00000073' }}>{t.partOfSpeech}</span></Title>
          <Flex vertical style={{ paddingLeft: '1rem' }}>
            <Text>{t.additionalInfo}</Text>
            <br />
            {t.examples && t.examples.length > 0 && (
              <>
                <Title level={5} style={{ marginBottom: 0 }}>Examples:</Title>
                {t.examples.map((e: any, ind: number) => (
                  <Flex key={ind} className='detail-set'>
                    <Text style={{ color: '#007AFF' }}>{` ${e.chinese} `}</Text>
                    <Text>{e.pinyin}&nbsp;</Text>
                    <Title level={5} style={{ margin: 0 }}>- {e.english}</Title>
                  </Flex>
                ))}
              </>
            )}
            <br />
            {t.synonyms && t.synonyms.length > 0 && (
              <>
                <Title level={5} style={{ marginBottom: 0 }}>Synonyms:</Title>
                {t.synonyms.map((e: any, ind: number) => (
                  <Flex key={ind} className='detail-set'>
                    <Text style={{ color: '#007AFF' }}>{` ${e.word} `}</Text>
                    <Text>{e.pinyin}&nbsp;</Text>
                    <Title level={5} style={{ margin: 0 }}>- {e.english}</Title>
                  </Flex>
                ))}
              </>
            )}
            <br />
            {t.antonyms && t.antonyms.length > 0 && (
              <>
                <Title level={5} style={{ marginBottom: 0 }}>Antonyms:</Title>
                {t.antonyms.map((e: any, ind: number) => (
                  <Flex key={ind} className='detail-set'>
                    <Text>
                      <span style={{ color: '#007AFF'}}>{` ${e.word} `}</span>
                      {e.pinyin}&nbsp;-&nbsp;
                    </Text>
                    <Title level={5} style={{ margin: 0 }}>{e.english}</Title>
                  </Flex>
                ))}
              </>
            )}
          </Flex>
        </Flex>
      ))}
    </Flex>
  )

  return (
    <Row className='w-full'>
      <SubHeader>
        <Breadcrumb items={breadcrumbItems} />
        {wordDetail && (
          <>
            <Title level={4} style={{ marginBottom: '0' }}>
              {`${wordDetail.simplified} ${wordDetail.pinyin ?? ''} `} 
            </Title>
            <Text>HSK 3.0: {wordDetail.hsk_3_0} &nbsp;&nbsp; Frequency: {wordDetail.frequency}</Text>
          </>
        )}
      </SubHeader>
      <Flex className='container w-full'>
        <Space className='bg-white w-full' style={{ borderRadius: 8, padding: '.5rem' }}>
          <Collapse ghost items={items} style={{ width: '100%' }} />
        </Space>
      </Flex>
    </Row>
  );
};

export default DictionaryDetailPage;