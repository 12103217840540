import { forwardRef, useState, useEffect } from "react";
import { Col, Row, Typography } from "antd";
import { BookFilled } from "@ant-design/icons";
import { Paragraph, Word } from "../../data/interfaces/document";

const { Text } = Typography;

interface RProps {
  index: number;
  showChinese: boolean;
  showPinyin: boolean;
  showEnglish: boolean;
  paragraph: Paragraph;
  isBookmarked: boolean;
  handleWordClick: (word: Word, event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleBookmark: (index: number) => void;
}

const TextRow = forwardRef(({ index, showChinese, showPinyin, showEnglish, paragraph, isBookmarked, handleWordClick, handleBookmark }: RProps, ref: any) => {
  const [hover, setHover] = useState<boolean>(false);
  const [supportsHover, setSupportsHover] = useState<boolean>(true);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(hover: hover)');
    setSupportsHover(mediaQuery.matches);

    const updateHoverSupport = (e: MediaQueryListEvent) => setSupportsHover(e.matches);
    mediaQuery.addEventListener('change', updateHoverSupport);
    return () => mediaQuery.removeEventListener('change', updateHoverSupport);
  }, []);

  const handleLongPress = (e: React.TouchEvent) => {
    e.preventDefault();
    if (!supportsHover) {
      handleBookmark(index);
    }
  };

  const bookmarkStatus = (isBookmarked: boolean) => (
    <BookFilled 
      style={{ position: 'absolute', left: '.5rem', top: '0.5rem', color: isBookmarked ? 'red' : '#D9D9D9', transform: 'rotate(90deg)', cursor: 'pointer' }} 
      onClick={() => !isBookmarked && handleBookmark(index)}
    />
  )

  return (
    <Row gutter={16} className='detail-header' style={{ flexDirection: 'column' }} ref={ref}>
      {showChinese && (
        <Col 
          className='chinese-section' 
          onMouseEnter={() => supportsHover && setHover(true)} 
          onMouseLeave={() => supportsHover && setHover(false)} 
          onTouchStart={(e) => {
            if (!supportsHover) {
              const timer = setTimeout(() => handleLongPress(e), 500); // 500ms for long press
              e.currentTarget.addEventListener('touchend', () => clearTimeout(timer), { once: true });
            }
          }}
          style={{ padding: '0 2rem' }}
        >
          {isBookmarked || (supportsHover && hover) ? bookmarkStatus(isBookmarked) : null}
          {paragraph.sentences?.map((s, s_index) => {
            let firstNonPuncFound = false;

            return (
              <span key={s_index} className='sentence'>
                {s.words.map((w, w_index) => {
                  let pinyin = '';

                  if (showPinyin) {
                    if (!firstNonPuncFound && w.partOfSpeech !== 'punctuation') {
                      firstNonPuncFound = true;
                      pinyin = w.pinyin ? w.pinyin.charAt(0).toUpperCase() + w.pinyin.slice(1) : '';
                    } else if (w.partOfSpeech === 'punctuation') {
                      pinyin = '';
                    } else {
                      pinyin = w.pinyin ?? '';
                    }
                  }

                  return (
                    <Text 
                      key={s_index + '_' + w_index}
                      onClick={(e) => handleWordClick(w, e)}
                      tabIndex={0}
                      style={{ 
                        fontSize: showPinyin ? '1.2rem' : '1rem',
                        display: 'inline-block',
                        wordBreak: 'keep-all',
                        whiteSpace: 'normal'
                      }}
                    >
                      {showPinyin ? (
                        <ruby style={{ display: 'inline-flex', flexDirection: 'column' }}>
                          <rp>(</rp>
                          <rt style={{ 
                            marginBottom: '.3rem', 
                            fontSize: '.6rem',
                            textAlign: 'center',
                            whiteSpace: 'nowrap'
                          }}>{pinyin}</rt>
                          <span className='selectable'>{w.text}</span>
                          <rp>)</rp>
                        </ruby>
                      ) : <span className='selectable'>{w.text}</span>}
                    </Text>
                  )
                })}
              </span>
            )
          })}
        </Col>
      )}
      {showEnglish && (
        <Text style={{ fontSize: '1rem', padding: '.5rem 2rem' }}>
          {paragraph.translation}
        </Text>
      )}
    </Row>
  )
});

export default TextRow;