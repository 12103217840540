import { useState } from 'react';
import { Button, Flex, Modal, Space, message } from 'antd';
import FlashCard from '../flashcards/FlashCard';
import { DocumentDto } from '../../data/interfaces/document';
import axiosInstance from '../../utils/axiosInstance';
import { formatDateTime } from '../../utils/dictionary';

interface RProps {
  open: boolean, 
  setOpen: (value: boolean) => void, 
  doc: DocumentDto
}

const ExportModal = ({ open, setOpen, doc }: RProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<React.Key[]>(() => doc.flashcards?.map(card => JSON.stringify({
    text: card.word,
    partOfSpeech: card.part_of_speech,
    translation: card.short_definitions?.join(' ')
  })) || []);

  const getRowSelection = (itemKey: string) => ({
    onChange: (selectedRowKeys: React.Key[]) => setSelectedRows(selectedRowKeys),
    selectedRowKeys: selectedRows,
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await Promise.race([
        axiosInstance.post('/api/create-deck', { deckName: doc.title + formatDateTime(), words: selectedRows.map(s => JSON.parse(s as string)) }),
        new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 5000))
      ])

      if (response && typeof response === 'object' && 'data' in response) {
        const { signedURL } = response.data as any;

        if (signedURL) {
          const anchor = document.createElement('a');
          anchor.href = signedURL;
          anchor.download = '';
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        } else {
          throw new Error('File URL is missing in the response.');
        }
      }
      setLoading(false);
      setOpen(false);
      setSelectedRows([]);
    } catch (error) {
      message.error((error as Error).message);
      setLoading(false);
    }
  }

  return (
    <Space onClick={(e) => e.stopPropagation()}>
      <Modal
        open={open}
        title='Export XML file'
        onCancel={() => setOpen(false)}
        footer={(
          <Flex className='w-full'>
            <Button key='cancel' onClick={() => setOpen(false)} style={{ borderRadius: 2 }}>Cancel</Button>
            <Button key='continue' type='primary' style={{ borderRadius: 2, marginLeft: 'auto' }} loading={loading} onClick={handleSubmit}>Export</Button>
          </Flex>
        )}
        centered
      >
        <Flex vertical gap={16} style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9', padding: '1rem 0' }}>
          <FlashCard doc={doc} words={doc.flashcards || []} getRowSelection={getRowSelection} selectable  />
        </Flex>
      </Modal>
    </Space>
  );
};

export default ExportModal;