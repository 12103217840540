import { ReactNode } from 'react';
import { Flex } from 'antd';

interface RProps {
  children: ReactNode
}

const SubHeader = ({ children }: RProps) => {

  return (
    <Flex vertical className='w-full container bg-white' gap={8} style={{ borderBottom: '1px solid #D9D9D9' }}>
      {children}
    </Flex>
  );
};

export default SubHeader;
