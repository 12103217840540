import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Flex, Row, Tabs, TabsProps, Select, Typography, message, Breadcrumb, Button, Popover } from 'antd';
import { BlockOutlined, BookFilled, DownOutlined, FileDoneOutlined, UpOutlined } from '@ant-design/icons';
import TextTabContent from '../../../components/documents/TextTab';
import WordTabContent from '../../../components/documents/WordTab';
import Spinner from '../../../components/layout/Spinner';
import PhraseTabContent from '../../../components/documents/PhraseTab';
import { DocumentDto } from '../../../data/interfaces/document';
import { getTextById } from '../../../utils/supabase';
import './index.css';

const { Title } = Typography;

const DocumentDetailPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [document, setDocument] = useState<DocumentDto | undefined>(undefined);
  const [selectedItems, setSelectedItems] = useState(['chinese']);
  const [bookIndex, setBookIndex] = useState<number | undefined>();
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const paragraphRefs = useRef<(HTMLDivElement | null)[]>([]);

  const breadcrumbItems = [
    {
      href: '/documents',
      title: 'Library'
    },
    {
      title: document?.title
    }
  ]

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setBookIndex(document?.bookmark);
  }, [document])

  const fetchData = async () => {
    try {
      if (id) {
        const response = await getTextById(id);
        if (response) {
          setDocument(response[0]);
        }
      }
    } catch (error) {
      message.error((error as Error).message)
    }
    setLoading(false);
  };

  const handleChange = (value: string[]) => {
    if (value.length === 0) {
      message.warning('Your must select at least one option!');
      return;
    }
    setSelectedItems(value);
  };

  const moreContent = () => {
    return (
      <Flex vertical gap={8} justify='start'>
        {bookIndex && (
          <Button type="link" style={{ color: '#1890ff', padding: '.5rem 0' }} onClick={scrollToPosition}>
            <BookFilled />
            Go to Bookmark
          </Button>
        )}
        <Button icon={<BlockOutlined style={{ fontSize: 16 }} />} href={`/documents/${document?.id}/flashcards`} style={{ padding: '.5rem 0' }}>
          Flashcards
        </Button>
        <Button icon={<FileDoneOutlined style={{ fontSize: 16 }} />} href={`/exercises/${document?.id}`} style={{ padding: '.5rem 0' }}>
          Exercises
        </Button>
      </Flex>
    )
  }

  const CustomTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => {
    return (
      <Flex justify='space-between' align='end' className='container bg-white' style={{ paddingTop: 0, paddingBottom: 0, borderBottom: '1px solid #D9D9D9' }}>
        <DefaultTabBar {...props} style={{ marginBottom: 0 }} />
        {props.activeKey === '1' && (!isMobile ? (
          <Flex align='center'>
            {bookIndex && (
              <Button type="link" style={{ color: '#1890ff', margin: '.3rem' }} onClick={scrollToPosition}>
                <BookFilled />
                Go to Bookmark
              </Button>
            )}
            <Button icon={<BlockOutlined style={{ fontSize: 16 }} />} href={`/documents/${document?.id}/flashcards`} style={{ margin: '.3rem' }} >
              Flashcards
            </Button>
            <Button icon={<FileDoneOutlined style={{ fontSize: 16 }} />} href={`/exercises/${document?.id}`} style={{ margin: '.3rem' }} >
              Exercises
            </Button>
          </Flex>
        ) : (
          <Popover
            placement="bottom" 
            content={moreContent} 
            trigger={'click'} 
            open={isPopoverOpen} 
            onOpenChange={(open: boolean) => setIsPopoverOpen(open)}
          >
            <span style={{ cursor: 'pointer' }}>
              More {isPopoverOpen ? <UpOutlined /> : <DownOutlined />}
            </span>
          </Popover>
        ))}
      </Flex>
    )
  }
  
  const scrollToPosition = () => {
    if (bookIndex && paragraphRefs.current[bookIndex] ) {
      const targetElement = paragraphRefs.current[bookIndex];
      if (targetElement) {
        const elementTop = targetElement.getBoundingClientRect().top + window.pageYOffset;
        const scrollToPosition = elementTop - window.innerHeight * 0.25;
        window.scrollTo({ top: scrollToPosition, behavior: 'smooth' })
      }
    }
  }

  const callbackForAddFlashcard = (payload: DocumentDto) => {
    setDocument(payload);
  }

  const options = [
    {
      label: 'Chinese',
      value: 'chinese',
    },
    {
      label: 'Pinyin',
      value: 'pinyin',
    },
    {
      label: 'English',
      value: 'english',
    },
  ];

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Text',
      children: <TextTabContent doc={document} items={selectedItems} callback={callbackForAddFlashcard} refs={paragraphRefs} bookIndex={bookIndex} setBookIndex={setBookIndex} />
    },
    {
      key: '2',
      label: 'Words',
      children: <WordTabContent doc={document} callback={callbackForAddFlashcard} />
    },
    {
      key: '3',
      label: 'Phrases',
      children: <PhraseTabContent />
    },
  ]

  return (
    <Row className='w-full flex-col'>
      <Flex className='flex-col w-full container bg-white' gap={8}>
        <Breadcrumb items={breadcrumbItems} />
        <Flex justify='space-between' className='detail-header'>
          <Title level={4} style={{ marginBottom: '0' }}>{document?.title}</Title>
          <Flex align='center' justify='space-between'>
            <Select
              mode="multiple"
              style={{ borderRadius: 2, alignSelf: 'end' }}
              defaultValue={['chinese']}
              value={selectedItems}
              onChange={handleChange}
              options={options}
            />
          </Flex>
        </Flex>
      </Flex>
      <Spinner loading={loading}>
        {!document?.json_structure ? (
          <Flex className='container' justify='center' style={{ paddingTop: '3rem' }}>
            <Title level={3}>This document is processing now.</Title>
          </Flex>
        ) : (
          <Row className='detail-header' style={{ marginLeft: 0, marginRight: 0 }} gutter={24}>
            <Tabs 
              className='w-full' 
              defaultActiveKey="1" 
              items={tabItems} 
              renderTabBar={CustomTabBar}
            />
          </Row>
        )}
      </Spinner>
    </Row>
  );
};

export default DocumentDetailPage;