import { Empty, Flex, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import { DictionaryDto } from "../../data/interfaces/dictionary";

const { Title } = Typography;

const SearchResult = ({searchResult}: {searchResult: DictionaryDto[]}) => {
  const navigate = useNavigate();

  const handleClick = (id: number) => {
    navigate(`/dictionary/${id}`);
  }

  if (searchResult.length === 0) {
    return <Empty description="Sorry we couldn't find any result" style={{ margin: '3rem 0' }} />;
  } else {
    return (
      <>
        {searchResult?.map((result, index) => (
          <Flex style={{ borderBottom: '1px solid #DDE1E6', padding: '12px 16px', cursor: 'pointer' }} justify="space-between" onClick={() => handleClick(result.id)}>
            <Title level={5} key={index} style={{ marginBottom: 0 }}>
              {result.simplified}
            </Title>
            <RightOutlined />
          </Flex>
        ))}
      </>
    )
  }
}

export default SearchResult;