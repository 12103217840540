import { useMediaQuery } from "react-responsive";
import { Flex, Table, TableColumnsType, Typography } from "antd";
import { DeleteOutlined, SoundOutlined } from "@ant-design/icons";
import { DataTypeForCardTable, FlashcardDto } from "../../data/interfaces/flashcard";
import { DocumentDto } from "../../data/interfaces/document";

const { Text } = Typography;

interface RProps {
  words: FlashcardDto[], 
  doc: DocumentDto, 
  handleCardDelete?: (value: DataTypeForCardTable) => void, 
  selectable?: boolean, 
  getRowSelection?: (key: string) => object
}

const FlashCard = ({ words, doc, handleCardDelete, selectable, getRowSelection }: RProps) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const columnsForDesktop: TableColumnsType<DataTypeForCardTable> = selectable ? [
    {
      title: 'Word',
      dataIndex: 'word',
      key: 'word',
      render: (w) => (
        <Flex align="center">
          <Text style={{ color: '#1890FF' }}>{w.word}</Text>
          <SoundOutlined style={{ marginLeft: '1rem', color: '#1890FF' }} />
        </Flex>
      ),
      width: 150
    },
    {
      title: 'Pinyin',
      dataIndex: 'pinyin',
      key: 'pinyin',
      width: 150
    },
    {
      title: 'Definition',
      dataIndex: 'definition',
      key: 'definition',
      width: 550
    },
  ] : [
    {
      title: 'Word',
      dataIndex: 'word',
      key: 'word',
      render: (w) => (
        <Flex align="center">
          <Text style={{ color: '#1890FF' }}>{w.word}</Text>
          <SoundOutlined style={{ marginLeft: '1rem', color: '#1890FF' }} />
        </Flex>
      ),
      width: 150
    },
    {
      title: 'Pinyin',
      dataIndex: 'pinyin',
      key: 'pinyin',
      width: 150
    },
    {
      title: 'Definition',
      dataIndex: 'definition',
      key: 'definition',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      render: (_, record) => (
        <DeleteOutlined 
          style={{ color: '#1890FF', alignSelf: 'end' }} 
          onClick={handleCardDelete ? () => handleCardDelete(record) : undefined} 
        />
      )
    }
  ]
  
  const columnsForMobile: TableColumnsType<DataTypeForCardTable> = [
    {
      title: 'Word',
      dataIndex: 'word',
      key: 'word',
      render: (w) => (
        <Flex vertical gap={8} className="w-full">
          <Flex align="center" justify="space-between">
            <Flex align="center" gap={8}>
              <Text style={{ color: '#1890FF' }}>{w.word}</Text>
              <Text>{w.pinyin}</Text>
              <SoundOutlined style={{ color: '#1890FF' }} />
            </Flex>
            {!selectable && <DeleteOutlined style={{ color: '#1890FF' }} />}
          </Flex>
          <Text>{w.short_definitions?.join(' ')}</Text>
        </Flex>
      ),
    },
  ]
  
  const data = words.map((w: FlashcardDto) => {
    return {
      key: JSON.stringify({text: w.word, partOfSpeech: w.part_of_speech, translation: w.short_definitions?.join(' ')}),
      word: w,
      pinyin: w.pinyin,
      definition: w.short_definitions?.join(' '),
      id: w.id,
      doc_id: doc.id
    }
  })

  return (
    <Flex className="w-full" align="center">
      <Table<DataTypeForCardTable>
        rowSelection={selectable && getRowSelection ? { type: 'checkbox', ...getRowSelection(doc.id) } : undefined}
        columns={isMobile ? columnsForMobile : columnsForDesktop}
        dataSource={data}
        showHeader={false}
        pagination={false}
        bordered={!isMobile}
        style={{ width: '100%' }}
        size="small"
      />
    </Flex>
  )
}

export default FlashCard;